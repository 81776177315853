<template>
  <div align="center">
    <br>
    <div class="card col-md-8 rounded shadow-lg" align="left">
      <div class="card-body">
        <div class="card-title">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <!--
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Compañía (*):</label>
                <input type="text" class="form-control col-md-6" v-model="item.cmp_id" disabled="true">
              </div>
            </div>
          </div>
        -->
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Usuario (*):</label>
                <select v-model="item.usu_id" class="form-control col-md-6" required autofocus>
                  <option v-for="usuario in usuarios" v-bind:value="usuario._id">
                    {{ usuario.usu_nmbre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Dependencia (*):</label>
                <select v-model="item.dep_id" class="form-control col-md-6" required autofocus>
                  <option v-for="dependencia in dependencias" v-bind:value="dependencia._id">
                    {{ dependencia.dep_nmbre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Modificar (*):</label>
                <input type="checkbox" class="form-control col-md-6" v-model="item.uxd_mdfcr">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Imprimir (*):</label>
                <input type="checkbox" class="form-control col-md-6" v-model="item.uxd_imprmr">
              </div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <router-link :to="{ name: 'DisplayUsuxdep' }" class="btn btn-secondary">Cancelar</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';

  //feather.replace();

  export default {
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: '',
        usuarios: [],
        dependencias: [],
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {
      this.fetchUsuario();
      this.fetchDependencia();

      this.add = this.$route.params.add;
      if (this.add){
        this.title = 'Crear Usuario por dependencia';
        this.buttontext = 'Agregar Usuario por dependencia';
      } else {
        this.title = 'Editar Usuario por dependencia';
        this.buttontext = 'Actualizar Usuario por dependencia';
        this.getItem(this.$route.params.id);
      }
    },
    methods: {
      setClose(close){
        this.close = close;
      },
      fetchUsuario()
      {
        let uri = '/usuarios/' + String(this.$store.state.company);
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.get(uri).then((response) => {
          this.usuarios = response.data;
        });
      },
      fetchDependencia()
      {
        let uri = '/deps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.dependencias = response.data;
        });
      },
      saveItem(){
        if (this.add){
          this.item.cmp_id = String(this.$store.state.company);
          let uri = '/uxd/add';
          //alert(this.item);
          this.axios.post(uri, this.item)
          .then(response => {
            this.$router.replace({ name: 'DisplayUsuxdep' });
            this.message = 'Registro guardado';
          })
          .catch(err => {
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/uxd/update/' + this.item._id;
          this.axios.post(uri, this.item).then((response) => {
            if (this.close){
              this.$router.push({name: 'DisplayUsuxdep'});
            }
            this.message = 'Registro guardado';
          });
        }
      },
      getItem(id)
      {
        let uri = '/uxd/edit/' + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;
        });
      }
    }
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
